import Soup from './Soup';

const Section = ({ description, soups }) => {

  return (
    <section className="flex flex-col m-4 p-2">
      <div className="my-2 text-left text-2xl">{description && description}</div>
      {soups && soups.map((soup) => <Soup key={soup.id} title={soup.title} price={soup.price} />)}
    </section>
  )
}

export default Section