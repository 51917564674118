import './App.css'
import { Routes, Route } from "react-router-dom";
import Front from './components/Front';
import Impressum from './components/Impressum';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Front />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="*" element={<Front />} />
      </Routes>
    </div>
  );
}

export default App;
