

const Soup = ({ title, price }) => {

  return (
    <article className="border border-gray-600 bg-white rounded-md flex flex-row text-xl justify-between m-2 p-2 hover:border-gray-400">
      <div className="whitespace-normal text-left pr-4">{title && title}</div>
      <div className="whitespace-nowrap">{price && price.toFixed(2)} €</div>
    </article>
  )
}

export default Soup