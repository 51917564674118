import logo_round from '../assets/img/logo_round.png'
import logo_long from '../assets/img/logo_long_remake.png'
import vacation from '../assets/img/vacation.svg'
import {
  useQuery,
  gql
} from "@apollo/client";
import { Link } from "react-router-dom";
import Section from './Section';
import { Page, Text, View, Image, Document, PDFDownloadLink, StyleSheet, Font } from '@react-pdf/renderer';
import pdfFont from '../assets/font/BalsamiqSans-Regular.ttf'
const moment = require('moment');

const GET_WEEK = gql`
  query GetWeeks {
    weeks {
      startOf
      endOf
      sections {
        id
        description
          soups {
            id
            title
            price
          }
      }
    }
  }
`

const Front = () => {

  const { loading, error, data } = useQuery(GET_WEEK);

  Font.register({ family: 'bals', src: pdfFont })
  const pdfStyle = StyleSheet.create({
    global: {
      fontFamily: 'bals'
    },
    logo: {
      width: '50%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '5%'
    },
    textWeek: {
      textAlign: 'center',
      marginTop: '5%',
      marginBottom: '5%',
      fontSize: '20px'
    },
    section: {
      marginTop: '1%',
      marginRight: '12%',
      marginBottom: '3%',
      marginLeft: '12%'
    },
    sectionTitle: {
      fontSize: '15px'
    },
    sectionText: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '1%',
      border: '1px',
      borderRadius: '5px',
      marginTop: '1%',
      marginBottom: '1%',
      fontSize: '12px'
    },
    greet: {
      textAlign: 'center',
      fontSize: '13px'
    }
  })

  const OldSchoolPdf = () => (
    <Document>
      <Page size="A4">
        <View style={pdfStyle.global}>
          <Image src={logo_long} style={pdfStyle.logo}></Image>
          <Text style={pdfStyle.textWeek}>Speisekarte vom {moment(data.weeks[0].startOf).format("DD.MM.YYYY")} bis {moment(data.weeks[0].endOf).format("DD.MM.YYYY")}</Text>
          {data && data.weeks[0].sections.map((section) => {
            return <View key={section.id} style={pdfStyle.section}>
              <Text style={pdfStyle.sectionTitle}>{section.description}</Text>
              {section.soups.map((soup) => <View key={soup.id} style={pdfStyle.sectionText}><Text style={{ paddingRight: '30px' }}>{soup.title}</Text><Text>{`${soup.price.toFixed(2)} €`}</Text></View>)}
            </View>
          })}
          <Text style={{ textAlign: 'center', fontSize: '13px' }}>{`Das Suppenkasper Team wünscht einen guten Appetit ${':)'}`}</Text>
          <Text style={{ textAlign: 'center', fontSize: '10px' }}>Alle Preise enthalten die gesetzlich vorgeschriebene MwSt.</Text>
        </View>
      </Page>
    </Document>
  )

  return (
    <div className="flex flex-col font-bals text-4xl select-none bg-pattern">
      <main className="mb-auto flex flex-col text-center max-w-5xl m-auto">
        <div className="w-full content-center p-4">
          <img src={logo_long} alt="logo" className="max-h-56 w-auto m-auto" />
          {loading && <div>Läd...</div>}
          {error && <div>Da ist leider etwas schiefgelaufen! &#x1F60A;</div>}
          {data && <h2 className="text-4xl my-6">Speisenkarte vom {moment(data.weeks[0].startOf).format("DD.MM.YYYY")} bis {moment(data.weeks[0].endOf).format("DD.MM.YYYY")}</h2>}
          {data && data.weeks[0].sections.map((section) => {
            return <Section key={section.id} description={section.description} soups={section.soups} />
          })}
          <div className="space-y-3">
            <div className="text-2xl">
              Das Suppenkasper Team wünscht einen guten Appetit &#x1F60A;
            </div>
            <div className="text-lg">
              Alle Preise enthalten die gesetzlich vorgeschriebene MwSt.
            </div>
            <div className="text-2xl">Ihr könnt auch gerne vorbestellen!</div>
            <div className="text-4xl">Tel. <a href="tel:02344597389" className="text-blue-500">0234 / 459 738 9</a></div>
          </div>
        </div>
        <div className="m-10 flex items-center justify-evenly bg-white p-6 space-x-2 border rounded-md">
          <div>Old school?</div>
          {data && data ? <PDFDownloadLink
            document={<OldSchoolPdf />}
            fileName={data && `Suppenkasper-${moment(data.weeks[0].startOf).format("DD.MM")}-${moment(data.weeks[0].endOf).format("DD.MM.YYYY")}.pdf`}
            className="bg-primary hover:bg-opacity-80 py-2 px-4 rounded-md text-white whitespace-nowrap"
          >
            {({ blob, url, loading, error }) => loading ? 'Läd' : 'PDF!'}
          </PDFDownloadLink> : <div>nix</div>}
        </div>
        <div className="w-full flex flex-row justify-between p-10">
          <div className="flex flex-col">
            <div className="text-left text-xl my-2">
              <div>Öffnungszeiten:</div>
              <div>Mo. - Do. ca. 11:00 - 15:00</div>
              <div>Fr. ca. 11:00 - Ausverkauft</div>
            </div>
            <div className="text-left text-xl my-2">
              <div>Adresse:</div>
              <div>Hattinger Str. 206</div>
              <div>44795 Bochum</div>
            </div>
          </div>
          <div className="flex flex-col justify-evenly">
            <img src={logo_round} alt="logo" className="max-h-40 w-auto self-center" />
          </div>
        </div>
        <Link to="/impressum" className="my-4 text-xl">Impressum / Datenschutzerklärung</Link>
      </main>
    </div>
  )
}

export default Front